import { useSpring, animated } from '@react-spring/web'
import { lerp } from '@kaliber/math'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { useMediaQuery } from '@kaliber/use-media-query'
import { easeOutQuad } from '/machinery/easings'
import { useReducedMotion } from '/machinery/useReducedMotion'
import { Icon } from '/features/buildingBlocks/Icon'
import { ImageCropped } from '/features/buildingBlocks/Image'

import media from '/cssGlobal/media.css'
import styles from './ImageWithTextAnimation.css'

import iconMask from '/images/image-with-text/mask.raw.svg'
import iconMaskReversed from '/images/image-with-text/mask-reversed.raw.svg'

export default function ImageWithTextAnimation({ image, reversed, layoutClassName }) {
  const { ref: imageRef, opacity, x } = useAnimation(reversed)

  return (
    <div className={cx(styles.component, reversed && styles.isReversed, layoutClassName)}>
      <animated.div className={styles.animation} ref={imageRef} style={{ opacity, x }}>
        {image?.asset && (
          <ImageCropped
            aspectRatio={20 / 13}
            layoutClassName={cx(styles.image, reversed && styles.isReversed)}
            {... { image }}
          />
        )}
      </animated.div>
      <Icon icon={reversed ? iconMaskReversed : iconMask} layoutClassName={styles.mask} />
    </div>
  )
}

function useAnimation(reversed) {
  const prefersReducedMotion = useReducedMotion()
  const isMd = useMediaQuery(`(min-width: ${media.breakpointMd})`)
  const start = (isMd ? 200 : 90) * (reversed ? 1 : -1)
  const end = (isMd ? 70 : 20) * (reversed ? 1 : -1)

  const [{ opacity }, opacitySpring] = useSpring(() => ({ opacity: 0.2 }))
  const [{ x }, xSpring] = useSpring(() => ({ x: start, config: { tension: 100, friction: 35 } }))
  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.center() },
    onChange(progression) {
      opacitySpring.start({ opacity: lerp({ start: 0.2, end: 1, input: easeOutQuad(progression) }) })
      xSpring.start({ x: lerp({ start, end, input: prefersReducedMotion ? 1 : easeOutQuad(progression) }) })
    }
  })

  return {
    ref,
    opacity,
    x
  }
}
